/* General Styles */

:root {
    --primary-purple: #2d1b4d;
    --secondary-purple: #4a2b7d;
    --accent-gold: #d4af37;
    --text-light: #ffffff;
    --text-dark: #333333;
    --gradient-bg: linear-gradient(120deg, #2d1b4d, #664698);
}

.courses-page {
    position: relative;
    text-align: center;
    padding: 60px 20px;
    min-height: 100vh;
    color: var(--text-dark);
    overflow: hidden;
    background-color: transparent;
}


/* Background Video Container */

.video-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: hidden;
}

.background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translate(-50%, -50%);
    object-fit: cover;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient( 180deg, rgba(45, 27, 77, 0.8) 0%,  rgba(204, 163, 255, 0.95) 100%);  
      z-index: -1;
}

.page-title {
    position: relative;
    z-index: 2;
    font-size: 3rem !important;
    font-weight: 800 !important;
    color: var(--accent-gold) !important;
    margin-bottom: 40px !important;
}

.courses-container {
    position: relative;
    z-index: 2;
    max-width: 1400px !important;
    margin: 0 auto !important;

    padding: 0 20px;
}

.course-card {
    background: var(--text-light);
    border-radius: 12px !important;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1) !important;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    height: 100%;
    display: flex !important;
    flex-direction: column !important;
    position: relative;
}

.course-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2) !important;
}

.course-image {
    width: 100%;
    height: 300px !important;
    object-fit: cover;
    object-position: center;
    display: block;
}


.course-card .MuiCardMedia-root,
.course-card > div > img,
.course-card > a > img,
.card-image-container {
    height: 300px !important;
    overflow: hidden !important;
}
.course-title {
    font-size: 1.25rem !important;
    font-weight: 700 !important;
    color: #5b2c7c !important; 
    margin-bottom: 10px !important;
}

.course-description {
    font-size: 14px !important;
    color: #7633b9 !important;
    margin-bottom: 15px !important;
}

.MuiCardContent-root {
    display: flex !important;
    flex-direction: column !important;
    height: 100% !important;
    justify-content: space-between !important;
}

.btn-glass {
    background-color: var(--primary-purple) !important;
    color: white !important;
    padding: 8px 20px !important;
    border-radius: 5px !important;
    transition: all 0.3s ease !important;
    margin-top: auto !important;
    text-transform: none !important;
    font-weight: 500 !important;
    min-width: 120px !important;
}

.btn-glass:hover {
    background-color: var(--secondary-purple) !important;
    transform: translateY(-2px);
}


/* Responsive Design */

@media screen and (max-width: 1200px) {
    .courses-container {
        padding: 0 15px;
    }
}

@media screen and (max-width: 960px) {
    .page-title {
        font-size: 2.5rem !important;
    }
}

@media screen and (max-width: 600px) {
    .page-title {
        font-size: 2rem !important;
    }
    .course-title {
        font-size: 1.2rem !important;
    }
    .course-description {
        font-size: 0.9rem !important;
    }
}