
.hero-slider {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh; /* Ensure it fills the viewport */
    width: 100%;
    position: relative;
    overflow: hidden;
    padding: 1rem;
  }
  
  .hero-slide {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 1rem;
    width: 100%;
  }
  
  .hero-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    max-width: 600px;
  }
  
  .hero-logo {
    width: 300px;
    /* margin-bottom: 1rem; */
  }
  
  .hero-title {
    font-size: 2.8rem;
    font-weight: 800;
    color: #d4af37;
  }
  
  .hero-description {
    font-size: 1.2rem;
    line-height: 1.5;
    color: #ffffff;
  }
  
  .cta-btn {
    padding: 12px 24px;
    font-size: 1.1rem;
    font-weight: bold;
    border-radius: 8px;
    border: none;
    background: #d4af37;
    color: #2d1b4d;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 1rem;
  }
  
  .cta-btn:hover {
    transform: translateY(-2px);
    background: #c19b2e;
  }
  
  /* Slide Image */
  .hero-image {
    width: 80%;
    max-width: 650px;
    border-radius: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  }
  
  /* Navigation Dots */
  .hero-nav {
    display: flex;
    gap: 10px;
    position: absolute;
    bottom: 40px; /* Adjusted to stay inside the hero section */
    left: 50%;
    transform: translateX(-50%);
  }
  
  .hero-dot {
    width: 20px;
    height: 12px;
    border-radius: 20%;
    background: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .hero-dot.active {
    background: #d4af37;
    transform: scale(1.3);
  }

  .cta-btn {
    display: inline-block;
    width: auto; /* Wrap around text */
    padding: 14px 24px; /* Adjust padding for better proportion */
    font-size: 1.2rem; 
    font-weight: bold;
    border-radius: 30px;
    border: none;
    background: #d4af37; /* Gold color */
    color: #2d1b4d;
    cursor: pointer;
    transition: all 0.3s ease;
    white-space: nowrap; /* Prevent text wrapping */
    text-align: center;
    box-shadow: 0px 4px 12px rgba(255, 223, 0, 0.5); /* Soft glow */
  }
  
  .cta-btn:hover {
    transform: translateY(-2px);
    background: #c19b2e;
    box-shadow: 0px 6px 20px rgba(255, 223, 0, 0.7);
  }

  /* Secondary Button - Learn More */
.learn-more-btn {
  background: transparent;
  color: white;
  border: 2px solid white;
  padding: 12px 22px;
}

.learn-more-btn:hover {
  background: rgba(255, 255, 255, 0.2);
}
@media screen and (max-width: 1200px) {
  .hero-title {
    font-size: 3rem;
  }
  
  .hero-description {
    font-size: 1.1rem;
  }
  
  .hero-logo {
    width: 180px;
  }
}

@media screen and (max-width: 992px) {
  .hero-slider {
    padding: 1.5rem;
    height: auto;
    min-height: 100vh;
  }

  .hero-title {
    font-size: 2.5rem;
  }
  
  .hero-image {
    max-width: 500px;
  }
}

@media screen and (max-width: 768px) {
  .hero-slide {
    grid-template-columns: 1fr;
    gap: 1.5rem;
    text-align: center;
  }
  .hero-content {
    text-align: center;
    align-items: center;
    order: 1;
    padding: 0 1rem;
  }

  .hero-title {
    font-size: 2rem;
  }

  .hero-description {
    font-size: 1rem;
  }

  .hero-image {
    order: 2;
    width: 90%;
    margin: 0 auto;
  }

  .cta-btn {
    width: 100%;
    max-width: 300px;
  }
}

@media screen and (max-width: 480px) {
  .hero-slider {
    padding: 1rem;
  }

  .hero-title {
    font-size: 1.75rem;
  }

  .hero-description {
    font-size: 0.95rem;
  }

  .hero-logo {
    width: 150px;
  }

  .cta-btn {
    font-size: 1rem;
    padding: 0.875rem 1.5rem;
  }

  .hero-dot {
    width: 16px;
    height: 6px;
  }
}

@media screen and (max-width: 360px) {
  .hero-title {
    font-size: 1.5rem;
  }

  .hero-description {
    font-size: 0.9rem;
  }

  .cta-btn {
    font-size: 0.95rem;
    padding: 0.75rem 1.25rem;
  }
}

/* Handle landscape orientation */
@media screen and (max-height: 600px) and (orientation: landscape) {
  .hero-slider {
    height: auto;
    min-height: 100vh;
    padding: 2rem 1rem;
  }

  .hero-slide {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  .hero-title {
    font-size: 2rem;
  }

  .hero-description {
    font-size: 0.95rem;
    margin-bottom: 1rem;
  }

  .hero-image {
    max-height: 60vh;
    object-fit: contain;
  }

  .hero-nav {
    bottom: 1rem;
  }
}
