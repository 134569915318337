/* Global Container */
.course-container {
    padding: 40px;
    background: linear-gradient(to right, #6a378c 30%, #cc8af2 100%);
    min-height: 100vh;
}

/* Header Section */
.course-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgb(46, 5, 90);
    padding: 20px 30px;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    flex-wrap: wrap;

    max-width: 1200px;
    width: 100%;
    margin: 0 auto; /* Center the header */
}

.course-image-container {
    flex: 1;
    display: flex;
    justify-content: center;
}

.course-image {
    width: 100%;
    max-width: 500px;
    border-radius: 12px;
}

.course-info {
    flex: 2;
    padding: 20px;
}

.course-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #cdb030;
}

.course-overview {
    font-size: 1.2rem;
    color: #e2abf3;
    margin: 10px 0;
}

/* Buttons */
.buttons {
    margin-top: 20px;
}

.buttons .btn-apply.MuiButton-contained {
    background-color: #a83eef !important;
    color: white;
    padding: 12px 20px;
    font-size: 1rem;
    border-radius: 8px;
    transition: 0.3s;
}

.buttons .btn-apply.MuiButton-contained:hover {
    background-color: #c87bf8 !important;
}

.buttons .btn-request.MuiButton-contained {
    background-color: #d39a2f !important;
    color: white;
    padding: 12px 20px;
    font-size: 1rem;
    border-radius: 8px;
    margin-left: 10px;
    transition: 0.3s;
}

.buttons .btn-request.MuiButton-contained:hover {
    background-color: #f4cd21 !important;
}

.btn-outline {
    border: 2px solid #8f0eb3;
    color: #3f004d;
    padding: 12px 20px;
    font-size: 1rem;
    border-radius: 8px;
    margin-left: 10px;
    transition: 0.3s;
}

.btn-outline:hover {
    background-color: #2a004d;
    color: white;
}

.btn-request {
    background-color: #d39a2f;
    color: white;
    padding: 12px 20px;
    font-size: 1rem;
    border-radius: 8px;
    margin-left: 10px;
    transition: 0.3s;
}

.btn-request:hover {
    background-color: #f4cd21;
}






/* Course Details Section with Glassmorphism */
.course-details {
    background: rgb(46, 5, 90);
    padding: 40px;
    border-radius: 16px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    margin-top: 30px;
    border: 1px solid rgba(255, 255, 255, 0.3);
}

/* Section Headers with Icons */
.section-header {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 32px;
    margin-bottom: 20px;
}

.section-icon {
    color: #c793e9;
}

.section-title {
    font-size: 1.8rem;
    font-weight: 600;
    color: #fefbff;
    margin: 0;
}

.course-description {
    font-size: 1.1rem;
    color: #d6b1fc;
}

/* Course Info Grid */
.info-label {
    font-size: 1.2rem;
    font-weight: 600;
    color: #eedbff;
}

.info-text {
    font-size: 1.1rem;
    color: #eec8ff;
}

/* Skills */
.skills-list {
    list-style-type: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 16px;
}

.skill-item {
    font-size: 1.1rem;
    color: #632d8d;
    padding: 12px 16px;
    background: #eed2f4;
    border-radius: 8px;
    transition: transform 0.2s ease;
}

.skill-item:hover {
    transform: translateX(5px);
}

/* Certification */
.certification-box {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.certification-logo {
    width: 80px;
    margin-right: 10px;
}

/* Accordion */
.accordion {
    background: #f2ddf7 !important;
    margin-top: 12px;
    border-radius: 12px;
    border: 1px solid rgba(123, 23, 190, 0.1);
    overflow: hidden;
}


.accordion-summary {
    background: #e6d2ed;
    padding: 8px 16px;
}

.accordion-summary:hover {
    background: #e6bcf9;
}

.accordion-summary .MuiTypography-root {
    color: #4d1970;
    font-weight: 500;
}

.accordion-summary .MuiSvgIcon-root {
    color: #270a3a;
}

.MuiAccordionDetails-root {
    padding: 16px 24px;
    background: rgb(247, 230, 255);
}

.target-audience {
    font-size: 1.2rem;
    color: #e2abf3;
    margin: 10px 0;
}




/* Responsive Design */
@media (max-width: 768px) {
    .course-header {
        flex-direction: column;
        text-align: center;
    }

    .course-info {
        padding: 0;
    }

    .buttons {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .btn-outline,
    .btn-request {
        margin-left: 0;
    }
}

