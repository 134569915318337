.placement-section {
    padding: 40px 0px;
    min-height: 100vh;
    position: relative;
    color: #ffffff;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    overflow: hidden;
    padding-bottom: 100px;
}

.video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    overflow: hidden;
}

.background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.overlay_placementSection {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    height: 100%;
    /* background: rgba(0, 0, 0, 0.6); */
    background: linear-gradient( 180deg, rgba(204, 163, 255, 0.95) 0%, rgba(45, 27, 77, 0.8) 100%);
   
}

.content-wrapper {
    position: relative;
    z-index: 1;
    padding: 60px 0;
    /* Remove horizontal padding */
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.placement-section h2 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 40px;
    color: #ffeb3b;
}

.placement-description {
    font-size: 1rem;
    color: #ffffff;
    margin-bottom: 30px;
    font-weight: 300;
    line-height: 1.5;
}

.placement-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    justify-items: center;
    align-items: start;
    margin-top: 40px;
    padding: 0 20px;
}

.placement-card {
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(10px);
    width: 100%;
    max-width: 300px;
    height: auto;
    padding: 20px;
    border-radius: 15px;
    text-align: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.placement-card:hover {
    transform: scale(1.05);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3);
}

.placement-card p {
    font-size: 1rem;
    color: #ddd;
    margin-bottom: 15px;
    line-height: 1.4;
}

.cta-section {
    margin-top: 0px;
    margin-bottom: 40px;
    /* Ensure the section is positioned properly */
    position: relative;
    z-index: 1;
}

.cta-button {
    background: linear-gradient(90deg, #ff9800, #f57c00);
    color: white;
    font-weight: 600;
    border-radius: 25px;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 1rem;
    padding: 12px 30px;
    display: inline-block;
    margin: 0 auto;
}

.cta-button:hover {
    background: linear-gradient(90deg, #f57c00, #e65100);
    transform: scale(1.1);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 768px) {
    .placement-section h2 {
        font-size: 1.8rem;
    }
    .placement-description {
        font-size: 0.9rem;
    }
    .placement-grid {
        grid-template-columns: 1fr;
        padding: 0 15px;
    }
    .cta-button {
        width: auto;
        min-width: 200px;
        padding: 12px;
    }
    .placement-section {
        padding-bottom: 80px;
    }
    .cta-section {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .placement-card {
        max-width: 100%;
        /* Allow cards to take full width of their container */
    }
    .content-wrapper {
        padding: 40px 15px;
        /* Add consistent horizontal padding */
    }
}

@media screen and (max-width: 480px) {
    .placement-section h2 {
        font-size: 1.5rem;
    }
    .placement-description {
        font-size: 0.8rem;
        margin-bottom: 20px;
    }
    .placement-card {
        padding: 15px;
    }
    .cta-button {
        font-size: 0.9rem;
        padding: 10px;
    }
    .placement-section {
        padding-bottom: 60px;
    }
    .cta-section {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .placement-grid {
        padding: 0 10px;
        /* Even smaller padding for mobile */
    }
    .content-wrapper {
        padding: 30px 10px;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1439px) {
    .placement-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 40px;
    }
    .placement-card {
        max-width: 90%;
    }
}

@media screen and (min-width: 1440px) {
    .placement-grid {
        grid-template-columns: repeat(3, 1fr);
        gap: 40px;
    }
    .placement-card {
        max-width: 320px;
    }
}