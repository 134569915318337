.fullstack-timeline {
    padding: 50px 20px;
    color: white;
    font-family: 'Arial', sans-serif;
    display: flex;
    align-items: flex-start;
    gap: 40px;
    overflow-x: hidden;
    flex-wrap: wrap;
}

.timeline-header {
    flex: 1;
    text-align: center;
    position: sticky;
    top: 50px;
    align-self: flex-start;
}

.timeline-header h1 {
    font-size: 2rem;
    color: #ffd700;
    font-weight: bold;
    margin-bottom: 20px;
    margin-left: 100px;
    margin-right: 0px;
}

.timeline {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    width: 100%;
}

.timeline-item {
    text-align: left;
    position: relative;
    padding: 15px 25px;
    background-color: rgba(27, 27, 88, 0.6);
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #ffd700;
    color: #0f0e17;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(255, 215, 0, 0.6);
    position: absolute;
    top: 20px;
    left: -80px;
}

.timeline-content h2 {
    font-size: 1.5rem;
    color: #ffd700;
    margin-bottom: 10px;
}

.timeline-content p {
    font-size: 1rem;
    line-height: 1.5;
}

.timeline-item:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.6);
}


/* Responsive Styles */

@media (max-width: 768px) {
    .fullstack-timeline {
        padding: 30px 10px;
        gap: 20px;
    }
    .timeline-header h1 {
        font-size: 1.5rem;
        margin-left: 50px;
    }
    .timeline-item {
        max-width: 90%;
        padding: 10px 20px;
    }
    .circle {
        width: 50px;
        height: 50px;
        font-size: 1.2rem;
        left: -60px;
    }
}

@media (max-width: 480px) {
    .fullstack-timeline {
        padding: 20px 10px;
        flex-direction: column;
        align-items: center;
    }
    .timeline-header h1 {
        font-size: 1.2rem;
        margin-left: 20px;
    }
    .timeline-item {
        max-width: 100%;
        padding: 8px 15px;
    }
    .circle {
        width: 40px;
        height: 40px;
        font-size: 1rem;
        left: -50px;
    }
    .timeline-content h2 {
        font-size: 1.2rem;
    }
    .timeline-content p {
        font-size: 0.9rem;
    }
}