.experience-section-wrapper {
    position: relative;
    overflow-x: hidden;
    background: linear-gradient(180deg, #002b5b 0%, #00509e 100%);
    margin-bottom: 0px;
}

.experience-section {
    position: sticky;
    top: 0;
    height: 100vh;
    max-width: 100%;
    padding: 80px 30px;
    color: #fdfdfd;
    text-align: center;
    background-size: cover;
    background-position: center;
    z-index: 10;
    overflow: hidden;
    overflow-x: hidden;
    object-fit: cover;
    /* width: 100vw; */
    margin-left: calc(-50vw + 50%);
}

.experience-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1;
}

.content-container {
    position: relative;
    z-index: 2;
}

.experience-title {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 30px;
    color: #f1c40f;
}

.cards-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 4fr 4fr;
    gap: 40px;
    justify-content: center;
    align-items: center;
    padding-top: 200px;
    padding-bottom: 20px;
}

.experience-card {
    background: rgba(0, 0, 0, 0.6);
    border-radius: 10px;
    width: 100%;
    max-width: 300px;
    justify-self: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.experience-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.4);
}

.card-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: #ffdd57;
}

.card-description {
    font-size: 1rem;
    font-weight: 400;
    color: #f5f5f5;
    line-height: 1.6;
}

.card-details {
    font-size: 0.9rem;
    color: #777;
    margin-top: 10px;
}

@media (max-width: 1024px) {
    .experience-title {
        font-size: 2.2rem;
    }
    .cards-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .experience-title {
        font-size: 2rem;
        margin-bottom: 20px;
    }
    .experience-section {
        padding: 60px 20px;
        height: auto;
        margin-left: 0;
        width: 100%;
    }
    .cards-container {
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }
    .experience-card {
        max-width: 100%;
        padding: 15px;
    }
    .card-title {
        font-size: 1.3rem;
    }
    .card-description {
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .experience-title {
        font-size: 1.6rem;
        margin-bottom: 15px;
    }
    .experience-section {
        padding: 40px 10px;
        height: auto;
    }
    .cards-container {
        grid-template-columns: 1fr;
        gap: 15px;
    }
    .experience-card {
        padding: 10px;
    }
    .card-title {
        font-size: 1.2rem;
    }
    .card-description {
        font-size: 0.8rem;
    }
}

@media (max-width: 768px) {
    .experience-card {
        width: 90%;
        scale: 0.9;
    }
    .cards-container {
        padding: 0 10px;
    }
}