.ai-machine-learning {
  background-color: #1d1d1d;
  color: #f2d56d;
  padding: 50px 20px;
  min-height: 100vh; /* Full page height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.ai-machine-learning h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
}

.steps {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.step {
  background-color: #333;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  width: 100%;
}

.step h3 {
  font-size: 2rem;
  color: #ffffff;
  margin-bottom: 10px;
}

.step p {
  margin: 5px 0;
}
