
/* General Styles */
:root {
  --primary-purple: #2d1b4d;
  --secondary-purple: #4a2b7d;
  --accent-gold: #d4af37;
  --text-light: #ffffff;
  --text-dark: #333333;
  --gradient-bg: linear-gradient(120deg, #2d1b4d, #4a2b7d);
}
    body {
        margin: 0;
        font-family: 'Poppins', sans-serif;
        line-height: 1.6;
        background-color: #f8f9fa;
        color: #333;
        overflow-x: hidden;
    }

    a:-webkit-any-link {
        text-decoration: none;
        color: white;
        cursor: pointer;
      }
    
    @keyframes fadeInUp {
        from {
            opacity: 0;
            transform: translateY(50px);
        }
        to {
            opacity: 1;
            transform: translateY(0px);
        }
    }
    
    .app {
        text-align: center;
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    }
    /* Hero Section */
    .hero-section {
        min-height: 100vh;;
        position: relative;
        overflow: hidden;
        padding: 0;
        width: 100%;
      }
      
      .hero-video {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: 100vh;
        transform: translate(-50%, -50%);
        object-fit: cover;
        z-index: 0;
      }
      
      .hero-overlay {
        position: relative;
        z-index: 2;
        
        height: 100vh;
        width: 100%;
        background: linear-gradient(90deg, rgba(45, 27, 77, 0.95) 0%, rgba(45, 27, 77, 0.8) 100%);
       
      }
      
      .hero-content-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 4rem;
        height: 100%;
        max-width: 1400px;
        margin: 0 auto;
      }
      
      .hero-content-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        text-align: left;
        padding-right: 5rem;
      }
      
      .hero-logo {
        width: 180px;
        margin-bottom: 0rem;
      }
      
      .hero-title {
        font-size: 3rem;
        font-weight: 800;
        color: #d4af37;
        margin: 0 0 1.5rem;
        line-height: 1.2;
      }
      
      .hero-description {
        font-size: 1.2rem;
        line-height: 1.6;
        color: #ffffff;
        margin-bottom: 2rem;
        max-width: 600px;
      }
      
      .cta-buttons {
        display: flex;
        gap: 1.5rem;
        margin-top: 2rem;
      }
      
      .cta-btn {
        padding: 1rem 2rem;
        font-size: 1.1rem;
        font-weight: 600;
        border-radius: 8px;
        transition: all 0.3s ease;
      }
      
      .cta-btn.primary {
        background: #d4af37;
        color: #2d1b4d;
        border: none;
        box-shadow: 0 4px 15px rgba(212, 175, 55, 0.3);
      }
      
      .cta-btn.primary:hover {
        transform: translateY(-2px);
      }
      
      .hero-content-right {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2rem;
      }
      
      .image-slider-container {
        width: 100%;
        height: 1000px;  /* Increased base height */
        border-radius: 24px;
        overflow: hidden;
        position: relative;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
      }
      
      .slider-image {
        width: 85%;
        height: 100%;
        object-fit: cover;
        border-radius: 24px;
      }
    /* Benefits Section */
    
    .benefits-section {
        padding: 60px 20px;
        background-color: white;
    }
    
    .benefits-section h2 {
        font-size: 2rem;
        margin-bottom: 40px;
    }
    
    .benefit-cards {
        /* display: flex; */
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 20px;
        padding: 20px;
        justify-content: center;
        flex-wrap: wrap;
    }
    
    .benefit-card {
        background: #f1f5f9;
        border: 1px solid #e0e7ff;
        border-radius: 10px;
        padding: 20px;
        /* max-width: 300px; */
        max-width: 100%;
        text-align: center;
        margin: 0 auto;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s;
    }
    
    .benefit-card:hover {
        transform: translateY(-10px);
    }
    
    .benefit-card h3 {
        color:var(--accent-gold);
        margin-bottom: 10px;
    }
    
    .benefit-card p {
        color: #555;
    }
    
    .timeline-section {
        padding: 0px 0px;
        background: linear-gradient(180deg, #00509e 0%, #00509e 100%);
        color: white;
    }
    
  /* Footer Styles */

.footer {
  background: linear-gradient(135deg, #2d1b4d, #4a2b7d);
  color: #ffffff;
  padding: 40px 0 20px;
  width: 100%;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  padding: 0 20px;
  text-align: left;
}

.footer-section {
  padding: 0 15px;
}

.footer-section h3 {
  font-size: 1.2rem;
  color: #d4af37;
  margin-bottom: 20px;
  position: relative;
  padding-bottom: 10px;
  text-align: left;
}

.footer-section h3:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 50px;
  background-color: #d4af37;
}

.footer-section p {
  line-height: 1.6;
  margin-bottom: 15px;
  color: #f0f0f0;
  display: flex;
  align-items: center;
  gap: 10px;
}

.footer-section.links ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.footer-section.links li {
  margin-bottom: 12px;
}

.footer-section.links a {
  color: #f7ebeb;
  text-decoration: none;
  transition: color 0.3s ease;
  display: flex;
  align-items: center;
}

.footer-section.links a:hover {
  color: #e4aaf5;
}

.social-icons {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.social-icons a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  font-size: 16px;
  transition: all 0.3s ease;
  text-decoration: none;
}

.social-icons a:hover {
  background-color: #d4af37;
  color: #2d1b4d;
  transform: translateY(-3px);
}

.footer-section.contact p i {
  color: #d4af37;
  font-size: 18px;
  width: 20px;
}

.footer-section.contact a {
  color: #f0f0f0;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-section.contact a:hover {
  color: #d4af37;
}

.footer-copyright {
  text-align: center;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 0.9rem;
  color: #ccc;
}

@media screen and (max-width: 1440px) {
  .hero-content-grid {
    padding: 2rem 4%;
    gap: 3rem;
  }

  .hero-title {
    font-size: 2.8rem;
  }

  .image-slider-container {
    height: 700px;
  }
}

/* Small laptop styles (1200px and down) */
@media screen and (max-width: 1200px) {
  .hero-content-grid {
    padding: 2rem 3%;
    gap: 2.5rem;
  }

  .hero-title {
    font-size: 2.5rem;
  }

  .image-slider-container {
    height: 600px;
  }
}

/* Tablet styles (768px and down) */
@media screen and (max-width: 768px) {
  .hero-content-grid {
    grid-template-columns: 1fr;
    padding: 2rem;
    height: auto;
    min-height: 100vh;
  }

  .hero-content-left {
    padding-right: 0;
    align-items: center;
    text-align: center;
  }

  .hero-title {
    font-size: 2.2rem;

  }

  .hero-description {
    font-size: 1rem;
    text-align: center;
  }

  .cta-buttons {
    justify-content: center;
  }

  .image-slider-container {
    height: 500px;
    width: 100%;
  }
}

/* Large mobile styles (480px and down) */
@media screen and (max-width: 480px) {
  .hero-content-grid {
    padding: 1.5rem;
    gap: 2rem;
  }

  .hero-title {
    font-size: 1.8rem;
    margin-top: 80px;

  }

  .hero-description {
    font-size: 0.95rem;
  }

  .image-slider-container {
    height: 400px;
  }

  .cta-buttons {
    flex-direction: column;
    width: 100%;
    gap: 1rem;
  }

  .cta-btn {
    width: 100%;
    max-width: 300px;
    padding: 0.8rem 1.5rem;
  }
}

/* Small mobile styles (375px and down) */
@media screen and (max-width: 375px) {
  .hero-content-grid {
    padding: 1rem;
  }

  .hero-title {
    font-size: 1.5rem;
    margin-top: 80px;

  }

  .hero-description {
    font-size: 0.9rem;
  }

  .image-slider-container {
    height: 350px;
  }
}

/* Extra small mobile styles (320px and down) */
@media screen and (max-width: 320px) {
  .hero-content-grid {
    padding: 1rem 0.5rem;
  }

  .hero-title {
    font-size: 1.4rem;
    margin-top: 80px;
  }

  .image-slider-container {
    height: 300px;
  }
}