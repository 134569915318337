.why-choose-us-container {
    position: relative;
    width: 100%;
    height: auto;
    padding: 100px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    overflow: hidden;
}

/* Background Video */
.background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

/* Dark Overlay */
.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(71, 43, 120, 0.9);
    z-index: 0;
}

.why-choose-us {
    position: relative;
    z-index: 1;
    padding: 50px 20px;
    max-width: 1200px;
    margin: auto;
}

.title {
    font-size: 2.8rem;
    font-weight: 700;
    margin-bottom: 15px;
    color: #ffffff;
}

.highlight {
    color: #6c63ff;
}

.subtitle {
    font-size: 1.3rem;
    margin-bottom: 50px;
    color: #e0e0e0;
    max-width: 800px;
    margin: auto;
    line-height: 1.6;
}

/* Features Grid */
.features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 50px;
    justify-items: center;
    margin-top: 20px;
}

.feature-card {
    background: rgba(255, 255, 255, 0.15);
    border: 1px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(12px);
    width: 100%;
    max-width: 350px;
    border-radius: 12px;
    padding: 30px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 25px rgba(255, 255, 255, 0.2);
}

.icon {
    font-size: 2.5rem;
    color: #6c63ff;
    margin-bottom: 15px;
}

.feature-title {
    font-size: 1.4rem;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 12px;
}

.feature-description {
    font-size: 1rem;
    color: #dcdcdc;
    line-height: 1.5;
}

/* Call-to-Action Button */
.cta-button {
    background-color: #6c63ff;
    color: #ffffff;
    font-size: 1.2rem;
    padding: 14px 32px;
    border-radius: 30px;
    margin-top: 50px;
    transition: all 0.3s ease;
    border: none;
    cursor: pointer;
}

.cta-button:hover {
    background-color: #5148d8;
    transform: scale(1.05);
}
